/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./footer.css";
// import footer_post_1 from "../../assets/images/resources/footer-post-1-1.png"
// import footer_post_2 from "../../assets/images/resources/footer-post-1-2.png"
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import new_one from "../../assets/images/new_one.png";

const Footer = ({ handleModalOpen }) => {    
  return (
    <div>
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget footer-widget__about">
                <a href="#">
                  <img
                    src={new_one}
                    width="155"
                    alt=""
                    style={{ marginTop: "-40px" }}
                  />
                </a>
                <div className="footer-widget__about-phone">
                  <SupportAgentIcon
                    sx={{
                      fontSize: "3rem",
                      color: "#fff !important",
                      marginTop: "-5px",
                      "@media(max-width:900px)": { fontSize: "2rem" },
                    }}
                  />
                  <div className="footer-widget__about-phone-content">
                    <span style={{ fontSize: "0.8rem" }}>Call timing</span>
                    <h3>011-40197147</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget footer-widget__link">
                <h3 className="footer-widget__title">Explore</h3>
                <ul className="list-unstyled footer-widget__link-list">
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/services">Our Services</a>
                  </li>
                  {/* <li>
                    <a href="/pylon.html">Latest News</a>
                  </li>
                  <li>
                    <a href="/pylon.html">Testimonials</a>
                  </li> */}
                  <li>
                    <a href="/contact" >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <div className="footer-widget footer-widget__post">
                <h3 className="footer-widget__title">Latest News</h3>
                <ul className="list-unstyled footer-widget__post-list">
                  <li>
                    <img
                      src={footer_post_1}
                      alt=""
                    />
                    <div className="footer-widget__post-list-content">
                      <span>16 Oct, 2020</span>
                      <h3>
                        <a href="/pylon/newsdetails.html">
                          We’re Providing the Quality Services
                        </a>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <img
                      src={footer_post_2}
                      alt=""
                    />
                    <div className="footer-widget__post-list-content">
                      <span>16 Oct, 2020</span>
                      <h3>
                        <a href="/pylon/newsdetails.html">
                          We’re Providing the Quality Services
                        </a>
                      </h3>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget footer-widget__contact">
                <h3>Contact</h3>
                <ul className="list-unstyled footer-widget__contact-list">
                  <li>
                    <a href="#">
                      <i
                        className="fa fa-envelope"
                        style={{ color: "#fff", fontSize: "1.1rem" }}
                      ></i>
                      support@matsyafincap.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i
                        className="fa fa-clock-o"
                        style={{ color: "#fff", fontSize: "1.1rem" }}
                      ></i>
                      Mon - Sat 10:00 AM - 6:00 PM
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i
                        className="fa fa-map-marker"
                        style={{ color: "#fff !important", fontSize: "1.1rem" }}
                      ></i>
                      306, Third Floor, Somdutt chamber- 2, Bhikaji Cama Place,
                      New Delhi, 110066
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="bottom-footer">
        <div className="container">
          <p>© Copyright 2023 by matsyafincap</p>
          <div className="bottom-footer__social">
            <a href="#">
              <i className="fa fa-facebook-square" />
            </a>
            <a href="#">
              <i className="fa fa-twitter" />
            </a>
            <a href="#">
              <i className="fa fa-instagram" />
            </a>
            <a href="#">
              <i className="fa fa-whatsapp" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
