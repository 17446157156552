import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { Collapse, Container, Link, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/images/Frame 1.png";
import new_one from "../../assets/images/new_one.png"
import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
// import { useNavigate, useNavigation } from "react-router-dom";

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });
  // const navigate = useNavigation()

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleNavigate = (event,path) =>{
    event.preventDefault()
    window.location.href = path
    // navigate(`${path}`)
  }

  const [submenus, setSubmenus] = React.useState({
    submenu: false,
    submenu1: false,
    submenu2: false,
    submenu3: false,
    submenu4: false,
  });
  const handleClickMenu = (menu) => {
    setSubmenus((prevSubmenus) => ({
      ...prevSubmenus,
      [menu]: !prevSubmenus[menu],
    }));
  };
  const { submenu, submenu1 } = submenus;
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 330 }}
      role="presentation"
    >
      <Box
        sx={{
          background: "#1d1c1c",
          color: "#fff",
          height: "100%",
          padding: "2rem 1rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            component={"img"}
            src={new_one}
            alt="side_icon"
            sx={{ width: "150px",marginTop:'-50px' }}
          />
          <CloseIcon
            sx={{ color: "#fff", fontSize: "2rem" }}
            onClick={toggleDrawer('left', false)}
          />
        </Box>
        <Box sx={{ paddingTop: "2.5rem" }}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography component={'p'}><Link to="/" sx={{color:'#fff', textDecoration:'none'}} onClick={(e)=>handleNavigate(e,"/")}>Home</Link></Typography>
            </Box>
            <Divider sx={{ background: "white", marginTop: "0.5rem" }} />
          </Box>

          <Box sx={{ paddingTop: "1rem" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography component={'p'}> <Link to="/about" sx={{color:'#fff', textDecoration:'none'}} onClick={(e)=>handleNavigate(e,"/about")}>About</Link></Typography>
            </Box>
            <Divider sx={{ background: "white", marginTop: "0.5rem" }} />
          </Box>

          <Box sx={{ paddingTop: "1rem" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography component={'p'}><Link to="/services" sx={{color:'#fff', textDecoration:'none'}} onClick={(e)=>handleNavigate(e,"/services")}>Services</Link></Typography>
            </Box>
            <Divider sx={{ background: "white", marginTop: "0.5rem" }} />
          </Box>
          <Box sx={{ paddingTop: "1rem",cursor:"pointer" }} onClick={() => handleClickMenu("submenu")}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography component={'p'}><Link to="/" sx={{color:'#fff', textDecoration:'none'}}>Policies & Codes  </Link></Typography>
              {submenu ? (
              <ExpandLess sx={{ fontSize:"1.2rem", cursor: "pointer" }} />
            ) : (
              <ExpandMore sx={{ fontSize:"1.2rem", cursor: "pointer" }} />
            )}
            </Box>
             <Divider sx={{ background: "white", marginTop: "0.5rem" }} />
            <Collapse in={submenu}>
            <Box
              sx={{
                // background: "#fcfcfc6b",
                pt: "0.2rem",
                borderRadius: "4px",
                "& a": {
                  color: "#fff !important",
                  textDecoration:"none"
                },
              }}
            >
              <Typography pt={"0.5rem"}>
                <Link to={"/privacy-policy"} onClick={(e)=>handleNavigate(e,"/privacy-policy")}>Policy Privacy</Link>
              </Typography>
              <Divider sx={{ background: "white", my: "0.5rem" }} />
              <Typography pt={"0.5rem"}>
                <Link to={"/terms-and-conditions"} onClick={(e)=>handleNavigate(e,"/terms-and-conditions")}>Terms & Conditions</Link>
              </Typography>
              <Divider sx={{ background: "white", my: "0.5rem" }} />
              <Typography pt={"0.5rem"}>
                <Link to={"/fair-practice"} onClick={(e)=>handleNavigate(e,"/fair-practice")}>Fair Practice Code</Link>
              </Typography>
              <Divider sx={{ background: "white", my: "0.5rem" }} />
              <Typography pt={"0.5rem"}>
                <Link to={"/interest-rate"} onClick={(e)=>handleNavigate(e,"/interest-rate")}>Interest Rate Policy</Link>
              </Typography>
              <Divider sx={{ background: "white", my: "0.5rem" }} />
              <Typography pt={"0.5rem"}>
                <Link to={"/grievance-redressal"} onClick={(e)=>handleNavigate(e,"/grievance-redressal")}>Grievance Redressal policy</Link>
              </Typography>
              <Divider sx={{ background: "white", mt: "0.5rem" }} />
             
            </Box>
          </Collapse>
          
           
          </Box>
          {/* <Box sx={{ paddingTop: "1rem",cursor:"pointer" }} onClick={() => handleClickMenu("submenu1")}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography component={'p'}><Link to="/services" sx={{color:'#fff', textDecoration:'none'}}>DLA & LSP  </Link></Typography>
              {submenu1 ? (
              <ExpandLess sx={{ fontSize:"1.2rem", cursor: "pointer" }} />
            ) : (
              <ExpandMore sx={{ fontSize:"1.2rem", cursor: "pointer" }} />
            )}
            </Box>
             <Divider sx={{ background: "white", marginTop: "0.5rem" }} />
             <Collapse in={submenu1}>
            <Box
              sx={{
                // background: "#fcfcfc6b",
                pt: "0.2rem",
                borderRadius: "4px",
                "& a": {
                  color: "#fff !important",
                  textDecoration:"none"
                },
              }}
            >
              <Typography pt={"0.5rem"}>
                <Link to={"/credit-lab"} onClick={(e)=>handleNavigate(e,"/credit-lab")}>CreditLab</Link>
              </Typography>
              <Divider sx={{ background: "white", mt: "0.5rem" }} />
             
             
            </Box>
          </Collapse>
          
           
          </Box> */}

          <Box sx={{ paddingTop: "1rem" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography component={'p'}><Link to="/contact" sx={{color:'#fff', textDecoration:'none'}} onClick={(e)=>handleNavigate(e,"/contact")}>Contact</Link></Typography>
            </Box>

            <Box>
              <Box sx={{ display: "flex", gap: "20px", alignItems:'center', padding:'1rem 0rem' }}>
                <Typography component={'span'}>
                  <i
                    className="fa fa-envelope"
                    style={{
                      color: "white",
                      font: "1.1rem",
                      borderRadius: "50%",
                      padding: "0.6rem",
                      paddingTop: "0.6rem",
                      background: "#008fd5",
                    }}
                  />
                </Typography>
                {/* <Typography component={"span"} sx={{marginLeft:'-5px'}}>bhupendramatsya@gmail.com</Typography> */}
                <Typography component={'span'} sx={{marginLeft:'-5px'}}>support@matsyafincap.com</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "20px", alignItems:'center' }}>
                <Typography component={'span'}>
                  <i
                    className="fa fa-phone"
                    style={{
                      color: "white",
                      font: "1.1rem",
                      borderRadius: "50%",
                      padding: "0.6rem",
                      paddingTop: "0.6rem",
                      background: "#008fd5",
                    }}
                  />
                </Typography>
                <Typography component={'span'}>011-40197147</Typography>
              </Box>
            </Box>

            <Box sx={{display:'flex', justifyContent:'space-between', padding:'1rem 0rem'}}>
              <Box sx={{display:"flex", gap:'10px', paddingLeft:"0.5rem"}}>
                <Typography component={'p'}><i className="fa fa-facebook-square" /></Typography>
                <Typography component={'p'}><i className="fa fa-twitter" /></Typography>
                <Typography component={'p'}><i className="fa fa-instagram" /></Typography>
                <Typography component={'p'}><i className="fa fa-whatsapp" /></Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Box>
            <Container>
              <Box
                sx={{
                  display: "none",
                  "@media(max-width:900px)": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  },
                }}
              >
                <Typography
                  component={"img"}
                  src={logo}
                  alt="logo here"
                  sx={{
                    width: "150px",
                    height: "50px",
                    "@media(max-width:900px)": {
                      height: "100px",
                    },
                  }}
                />
                <MenuIcon
                  onClick={toggleDrawer(anchor, true)}
                  sx={{ fontSize: "2.8rem", marginTop: "-20px" }}
                />
              </Box>
            </Container>
          </Box>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
