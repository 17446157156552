import { Box,  Typography } from "@mui/material";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import PinDropIcon from "@mui/icons-material/PinDrop";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import PersonIcon from '@mui/icons-material/Person';
import phone2 from "../../assets/images/phone2.jpg";

const Contact = () => {

  return (
    <Box sx={{ paddingBottom: "8rem" }}>
      <Box
        sx={{
          background: `url(${phone2})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 60%",
          height: "300px",
          "@media (max-width:900px)": {
            backgroundSize: "cover",
            backgroundPosition: "65%",
            backgroundRepeat: "no-repeat",
            height: "300px",
          },
        }}
      ></Box>
      <Box>
        <Box className="about-one__content-about">
          <Box className="block-title text-center">
            <Typography component={"p"}>Contact us</Typography>
            <h2>Our Contacts</h2>
          </Box>
        </Box>
      </Box>
        {/* <Container> */}
          <Box
            sx={{
              display: "flex",
              // gap: "10px",
              justifyContent:'space-evenly',
              "@media(max-width:900px)":{
                flexDirection:'column',
                alignItems:'center',
                gap:'4rem'
              }
            }}
          >
            <Box
              sx={{
                width: "30%",
                color: "#000",
                background: "#eee5e53b",
                border: "1px solid #fff",
                padding: "3rem 4rem",
                boxShadow: "0px 0px 10px 0px #a3a2a2",
                borderRadius: "0.8rem",
                height: "380px",
                "@media(max-width:1200px)": {
                  width:'45%'
                },
                "@media(max-width:900px)": {
                  margin: "0rem",
                  padding: "3rem 3rem",
                  width: "93%",
                  height:'422px',
                  left:'3.5%'
                },
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-around",
              "@media(max-width:900px)":{
                flexDirection:'column',
                gap:"20px"
              } }}>
                <Box>
                  <Typography
                    component={"p"}
                    sx={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "1.3rem",
                      padding: "1rem 0rem",
                      color: "#373232",
                    }}
                  >
                    Office
                  </Typography>
                  <Box
                    sx={{ display: "flex", gap: "40px", padding: "0.56rem" }}
                  >
                    <Typography component={"p"}>
                      <EmailIcon />
                    </Typography>
                    <Typography component={"p"}>
                      support@matsyafincap.com
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "40px", padding: "0.56rem" }}
                  >
                    <Typography component={"p"}>
                      <CallIcon />
                    </Typography>
                    <Typography component={"p"}>011-40197147</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "40px", padding: "0.56rem" }}
                  >
                    <Typography component={"p"}>
                      <AlarmOnIcon />
                    </Typography>
                    <Typography component={"p"}>
                      Mon - Sat 10:00 AM - 6:00 PM
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "40px", padding: "0.56rem" }}
                  >
                    <Typography component={"p"}>
                      <PinDropIcon />
                    </Typography>
                    <Typography component={"p"}>
                      {" "}
                      306, Third Floor, Somdutt chamber- 2,{" "}
                      <Typography component={"br"} />
                      Bhikaji Cama Place, New Delhi, 110066
                    </Typography>
                  </Box>
                </Box>
                {/* <Box>
<Typography component={'p'} sx={{textAlign:'center', fontWeight:'600', fontSize:'1.3rem', padding:'1rem 0rem',color:'#373232'}}>Grievance Officer</Typography>
            <Box sx={{ display: "flex", gap: "40px", padding: "0.56rem" }}>
              <Typography component={"p"}>
                <EmailIcon />
              </Typography>
              <Typography component={"p"}>support@matsyafincap.com</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "40px", padding: "0.56rem" }}>
              <Typography component={"p"}>
                <CallIcon />
              </Typography>
              <Typography component={"p"}>011-40197147</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "40px", padding: "0.56rem" }}>
              <Typography component={"p"}>
                <AlarmOnIcon />
              </Typography>
              <Typography component={"p"}>
                Mon - Sat 10:00 AM - 6:00 PM
              </Typography>
            </Box>

            </Box> */}
              </Box>
            </Box>


            <Box
              sx={{
                top: "80px",
                left: "50%",
                color: "#000",
                background: "#eee5e53b",
                border: "1px solid #fff",
                padding: "3rem 4rem",
                boxShadow: "0px 0px 10px 0px #a3a2a2",
                borderRadius: "0.8rem",
                height: "380px",
                width:'30%',

                "@media(max-width:1200px)": {
                width: "47%",

                },
                "@media(max-width:900px)": {
                  margin: "0rem",
                  padding: "3rem 3rem",
                  width: "93%",
                  top:"580px",
                  left:'3.5%'
                },
              }}
            >
              <Typography
                component={"p"}
                sx={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "1.3rem",
                  padding: "1rem 0rem",
                  color: "#373232",
                }}
              >
                Grievance Officer
              </Typography>
              <Box sx={{ display: "flex", gap: "40px", padding: "0.56rem" }}>
                <Typography component={"p"}>
                  <PersonIcon />
                </Typography>
                <Typography component={"p"}>Anil Kumar Agarwal,
Director</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "40px", padding: "0.56rem" }}>
                <Typography component={"p"}>
                  <EmailIcon />
                </Typography>
                <Typography component={"p"}>support@matsyafincap.com</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "40px", padding: "0.56rem" }}>
                <Typography component={"p"}>
                  <CallIcon />
                </Typography>
                <Typography component={"p"}>011-40197147</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "40px", padding: "0.56rem" }}>
                <Typography component={"p"}>
                  <AlarmOnIcon />
                </Typography>
                <Typography component={"p"}>
                  Mon - Sat 10:00 AM - 6:00 PM
                </Typography>
              </Box>
            </Box>
              {/* <Box sx={{ display: "flex", gap: "40px", padding: "0.56rem" }}>
                <Typography component={"p"}>
                  <PinDropIcon />
                </Typography>
                <Typography component={"p"}>
                  {" "}
                  306, Third Floor, Somdutt chamber- 2,{" "}
                  <Typography component={"br"} />
                  Bhikaji Cama Place, New Delhi, 110066
                </Typography>
            </Box> */}
          </Box>
          <Box sx={{
            mt:"4rem !important",
            width:{lg:"74%",xs:"95%"},
            m:"0 auto"
          }}>
          <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14016.309283071627!2d77.1877414!3d28.5674408!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1dc855e727a9%3A0xac5792fe49ff72f6!2sMatsya%20Fincap%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1709205879582!5m2!1sen!2sin" width="100%" height="450" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Box>
        {/* </Container> */}
    </Box>
  );
};

export default Contact;
